.form-input-container{
  margin-bottom: 16px;
  &.type-file{
    margin-bottom: 6px;
  }
  &:last-child{
    // margin-bottom: 0;
  }
}
.form-input {
  margin-bottom: 0px;
  .label {
    word-wrap: break-word;
  }
  .ant-form-item-label {
    padding-bottom: 0;
  }

  .ant-form-item-control-input {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.08);
    border: 1px solid #BDBDBD;
    border-radius: 10px;
    width: 100%;
    height: 40px;
    padding: 0px 8px;
  }

  .w-90pix {
    min-width: 90px;
  }

  .w-70pix {
    width: 70px;
  }

  .w-60pix {
    width: 60px !important;
  }

  .ant-form-item-explain {
    height: 16px;
  }

  .req {
    position: relative;

    &::before {
      display: none !important;
    }

    &::after {
      position: relative;
      display: inline-block;
      color: #FF4D4F;
      font-size: 1.4rem;
      line-height: 1;
      content: "*" !important;
      margin-left: 3px;
    }
  }
  &.layout-horizontal {
    .ant-form-item-row {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  &.type-file {
    .select-btn {
      line-height: 16px;
      border-radius: 24px;
      min-width: 96px;
      border: 1px solid #3d92e0;
    }

    .file-entry {
      padding: 4px 0;
      text-wrap: nowrap;
      .btn-delete {
        width: 24px;
        height: 24px;
      }
    }

    &.layout-horizontal {
      .ant-form-item-control-input-content{
        justify-content: flex-end;
        display: flex;
      }
      .ant-form-item-control-input{
        padding: 0;
      }
      .ant-form-item-control,
      .ant-form-item-label {
        width: 49%;
      }

      .btn-sec {
        width: fit-content;
      }

      .ant-form-item-label {
        align-items: center;
        display: flex;
      }

      
    }
  }

  &.type-file,
  &.type-radio,
  &.type-checkbox {
    .ant-form-item-control-input {
      box-shadow: none;
      border: none;
      border-radius: 0;
      height: auto;
    }
  }

  &.type-textarea {
    .ant-form-item-control-input {
      min-height: 40px;
      height: fit-content;
    }
  }

  &.type-text,
  &.type-search {
    .ant-form-item-required {}
  }

  &.type-quantity,
  &.type-duration {
    .btn {
      width: 40px;
      min-width: 40px;
      height: 28px;
      padding: 0;
      border-radius: 4px;
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
    }

    .text-hr {
      width: 100px;
      margin: 0 4px;
    }

    .ant-select-selector {
      border: none !important;
    }

    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      line-height: 2.5;
    }
  }
  &.type-select,
  &.type-autocomplete{
    &.multiple{
      .select-input,
      .ant-select-selector{
        min-height: 36px;
        height: auto;
      }
      .ant-form-item-control-input{
        min-height: 40px;
        height: auto;
      }
    }
  }
  .border {
    border: 1px solid transparent;
    border-radius: 2px;
  }

  .ant-input-affix-wrapper {
    border-color: transparent !important;

    .input-ele {
      padding: 0 !important;

      &:focus {
        outline: none;
        box-shadow: none;
        border: none;
      }
    }
  }

  .textarea-input {
    resize: none;
    width: 100%;
    &.read-only {
      border: none;
    }
  }

  .select-input .ant-select-selector {
    // border: 1px solid #D9D9D9;
    border: none;
    border-radius: 2px;
  }


  .select-input {
    padding-left: 0;
    height: 36px;

    .ant-select-selection-placeholder {
      line-height: 36px;
    }
  }

  .select-input .ant-select-selection-search-input {
    height: inherit;
  }

  .ant-input {
    outline: none;
  }

  .select-input .ant-select-selector,
  .text-input {
    outline: none;
    color: inherit;
    line-height: 12px;
    width: 100%;
    height: 36px;
    resize: none;
    background-color: transparent;
    margin-top: 0;
    padding: 0;

    &.read-only {
      border: none;
      height: auto;
    }

    &.disabled {
      cursor: not-allowed;
      background-color: #EFEFEF;
    }

    .input-ele {
      padding: 4px 8px;
      background-color: transparent;
      color: inherit;
    }

    &.disabled.read-only {
      background-color: transparent;
    }
  }

  .text-input {
    padding: 0;

    .input-suffix {
      padding: 0 4px;
      // border-left: 1px solid #D9D9D9;
    }

    &:focus {
      outline: none;
      // border-color: #D9D9D9;
      box-shadow: none;
    }
  }

  .file-list {
    max-height: 200px;

    .file-icon {
      margin-right: 8px;
    }

    .file-entry {
      // width: calc(100% - 60px);
      padding: 4px 8px;
      border: 1px solid #D9D9D9;
      border-radius: 2px;
      margin-bottom: 8px;
    }

    .btn-delete {
      cursor: pointer;
      padding: 4px;
      align-self: unset;
      margin-left: 8px;
    }
  }

  .cke_editable {
    min-height: 90px;
    border-radius: 2px;
    border: 1px solid #D9D9D9;
    background: #FFF;
    outline: none;
  }

  .suffix-pass-input {
    .pass-suffix {
      width: 40%;
      height: 36px;
      border: 1px solid #D9D9D9;
      border-right: none;
      border-radius: 0 0 0 0;
      background-color: #FAFAFA;
    }

    .input-suffix {
      border: none;
      cursor: pointer;
    }
  }

  &.req-colon {
    .ant-form-item-required {
      margin-right: 8px;

      &::before {
        display: inline-block !important;
        content: ":" !important;
        color: inherit !important;
        position: absolute;
        left: 95%;
      }
    }
  }

  .ant-radio-group {
    .ant-radio-wrapper {
      // width: calc(50% - 8px);
    }
  }
}
.file-list{
  margin-bottom: 16px;
}
.dropdown-list {
  .option[aria-selected="true"] {
    background-color: transparent !important;
    color: #0033CC;
  }
}

.dropdown {
  border: 1px solid #D9D9D9;

  .icon {
    margin-right: 0.8rem;
  }

  &:focus,
  &:hover {
    outline: none !important;
    border: 1px solid #D9D9D9;
  }
}

.dropdown-item {

  .icon,
  .label {
    color: inherit;
  }

  .icon {
    margin-right: 0.8rem;
  }

  &.Mui-selected {
    background-color: transparent !important;
  }

  &.primary:hover {
    background-color: #fff !important;

    .icon,
    .label {
      color: #0033CC;
    }
  }
}

.ant-input::placeholder {
  appearance: none !important;
  font-size: 1.4rem !important;
  line-height: 1.2 !important;
  color: #BDBDBD !important;
  font-family: 'Karla', sans-serif;
}