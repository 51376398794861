.doc-table {
  table {
    height: 1px;
  }

  .ant-table {
    background: transparent;
  }

  .expand-cell {
    transition: transform 0.25s;
    transform: rotate(180deg);

    &.expand {
      transform: rotate(0deg)
    }
  }

  .ant-table-body {
    table {
      border-spacing: 0 8px;
    }
  }

  .ant-table-header {
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
    border-radius: 8px !important;

    table {
      border-radius: 8px;
    }
  }

  .ant-table-thead {
    .ant-table-column-sorters {
      border-right: 1px solid #f2f2f2;

      .cell {
        border-right: none !important;
      }
    }
  }

  .ant-table-thead,
  .ant-table-row {
    .cell {
      border-right: 1px solid #f2f2f2;
    }
  }

  .ant-table-row,
  .ant-table-thead {
    .ant-table-cell {
      padding: 9px 2px;
      transition: none;
      background-color: #FFF;
      border-bottom: none;
      overflow: hidden;

      &::before {
        display: none;
      }

      &:first-child {
        border-radius: 8px 0px 0px 8px;
      }

      &:last-child {
        border-radius: 0px 8px 8px 0px;

        .header-title {
          border-right: none;
        }
      }
    }

    .cell {
      padding: 0px 5px;
      height: 100%;
      text-align: left;
      position: relative;

      &.no-border {
        border-right: none;
      }
    }
  }

  .ant-table-row {
    .ant-table-cell-row-hover {
      transition: none;
      background: #333 !important;
      color: #FFF;
      cursor: pointer;

      .cell {
        border-color: #4f4f4f;
      }
    }
  }

  .ant-table-expanded-row .ant-table-cell-row-hover {
    background: transparent !important;
    color: inherit;
    cursor: text;
  }

  .ant-table-expanded-row>.ant-table-cell {
    background-color: #F2F2F2;
    padding: 0;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .ant-table-row-expand-icon-cell {
    width: 0px;
  }

  .pr-indicator {
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    opacity: 0;
    background-image: linear-gradient(135deg, #219653 50%, transparent 50%);
  }
}

.table-ct {
  padding: 0.8rem;

  .ant-pagination {
    min-height: 56px;
    padding: 0 8px;
    margin-top: 16px;
  }
}

.trans-table {
  padding: 0;

  .ant-table-cell {
    background-color: transparent !important;

    .cell {
      padding: 0;
      border-right-color: #E0E0E0 !important;
    }
  }
}

.data-table-search {
  padding: 0.8rem;

  .input {
    margin-bottom: 0;
  }

  .label-cont {
    display: none;
  }

  .reset {
    border: 1px solid #D9D9D9;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 2px;
    margin: 0 0.8rem;
  }
}

.ant-table-filter-column,
.ant-table-column-sorters {
  font: inherit !important;
  color: inherit !important;

  .ant-table-column-title {
    font: inherit !important;
    color: inherit !important;
  }
}

.thumb-view {
  margin: 0;

  fa-icon {
    font-size: 1.25rem;
    margin-bottom: 5px;
  }

  span {
    text-align: center;
    opacity: 0.6;
  }
}

.row-disable {
  .ant-table-cell {
    background-color: #F2F2F2 !important;
  }
}