.cFFF {
  color: #FFF;
}

.c333 {
  color: #333;
}
.c777{
  color: #777;
}
.c4F4F4F{
  color: #4f4f4f;
}
.c00085 {
  color: rgba(0, 0, 0, 0.85);
}

.c828282 {
  color: #828282;
}

.c0133CC {
  color: #0133CC;
}

.cE0E0E0 {
  color: #E0E0E0;
}

.cBDBDBD {
  color: #bdbdbd;
}

.c1E686D {
  color: #1E686D;
}

.c3B92E0 {
  color: #3B92E0;
}

.cF2665E {
  color: #F2665E;
}

.c3d92e0 {
  color: #3d92e0;
}
.c219653{
  color: #219653;
}
.bg219653 {
  background-color: #219653;
}

.bgFFF {
  background-color: #FFF;
}

.bgTrans {
  background-color: transparent;
}

.bg44bb44 {
  background-color: #44bb44;
}

.bgF2F2F2 {
  background-color: #f2f2f2;
}

.bg3B92E0 {
  background-color: #3d92e0;
}

.bgE5E5E5 {
  background-color: #E5E5E5;
}

.bgE0E0E0 {
  background-color: #E0E0E0;
}

.bg333 {
  background-color: #333333;
}

.bgF2665E {
  background-color: #F2665E;
}

.bg27AE60 {
  background-color: #27ae60;
}

.bgBDBDBD {
  background-color: #bdbdbd;
}
.bg828282{
  background-color: #828282;
}
.bgF2C94C{
  background-color: #F2C94C;
}