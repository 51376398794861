@font-face {
  font-family: 'icomoon';
  font-display: auto;
  src: url('assets/fonts/icomoon.eot?66pipe');
  src: url('assets/fonts/icomoon.eot?66pipe#iefix') format('embedded-opentype'),
    url('assets/fonts/icomoon.ttf?66pipe') format('truetype'),
    url('assets/fonts/icomoon.woff?66pipe') format('woff'),
    url('assets/fonts/icomoon.svg?66pipe#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-up:before {
  content: "\e905";
}

.icon-correct:before {
  content: "\e904";
}

.icon-search:before {
  content: "\e900";
}

.icon-pencil:before {
  content: "\e901";
}

.icon-key:before {
  content: "\e902";
}

.icon-arrow-down:before {
  content: "\e903";
}