.login-screen {
  .logo-icarus {
    width: 245px;
    height: 50px;
    margin-right: 10px;
  }

  .title-row {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  .login-form {
    margin-top: 40px;

    .ant-form-item-control-input {
      background-color: #FFF;
    }
  }

  .btn-forgot {
    margin-top: 16px;
  }

  .btn-login {
    margin-top: 16px;
  }

  .password-input {
    border: none;
    padding: 0;
    background-color: transparent;
  }

  .showPass {
    cursor: pointer;
    color: #BDBDBD;
    font-size: 18px;
  }

  .login-spinner {
    margin: 0 auto !important;
    // left: calc(50% - 12px);
  }

  .mat-spinner::ng-deep circle {
    stroke: #fff;
  }
}

.outer-table-row {
  border-radius: 8px;

  .table-empty-row {
    height: 10px;
    visibility: hidden;
    background-color: transparent;
  }

  .outer-tr-body {
    // height: 100%;
    margin-bottom: 16px;
    // display: table-row;
    background-color: transparent;
    border-radius: 8px !important;
    -webkit-border-radius: 8px !important;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
  }

  .outer-row-cell {
    // display: table-cell;
    height: 60px;
    overflow: hidden;
    text-align: left;
    position: relative;
    vertical-align: top;
    background-color: #FFF;
    border-radius: 8px;
  }

  .outer-cell-content {
    display: flex;
    align-items: center;
    word-break: break-word;
    padding: 0px 24px 0px 16px;
    margin: 15px 0px;
  }

  &.brands .toggle-sec {
    padding-bottom: 0;

    &.open {
      padding-bottom: 12px;
    }
  }
}

.white-table {
  width: 100%;
  display: table;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0px;
  height: 1px;

  .left-corner {
    border-radius: 8px 0px 0px 8px;
  }

  .right-corner {
    border-radius: 0px 8px 8px 0px;
  }

  .cell-divider {
    border-right: 1px solid #F2F2F2;
  }

  .table-row {
    display: table-row-group;
    border-radius: 0px 0px 8px 8px;
    background-color: #F2F2F2;

    &:last-child {
      margin-bottom: 12px;
    }
  }

  .table-header {
    display: table-header-group;
    border-radius: 8px !important;
    background-color: transparent;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);

    .header-cell {
      cursor: pointer;
      display: table-cell;
      padding: 9px 2px;
      background-color: #FFF;
    }

    .header-content {
      padding: 12px 5px 10px 5px;
      text-align: left;
      display: flex;
      align-items: flex-end;
    }
  }

  .tr-body {
    height: 100%;
    display: table-row;
    background-color: transparent;
    border-radius: 8px !important;
    -webkit-border-radius: 8px !important;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);

    .details-cell {
      background-color: transparent !important;

      .cell-content {
        align-items: flex-start;
        margin: 15px 3px !important;
        padding: 0px 10px !important;
      }

      .cell-divider {
        border-color: #E0E0E0;

        &:hover {
          border-color: #E0E0E0;
        }
      }
    }

    .details-cell,
    .row-cell {
      display: table-cell;
      height: 100%;
      overflow: hidden;
      text-align: left;
      position: relative;
      vertical-align: top;
      background-color: #FFF;

      &:empty {
        display: none
      }
    }

    .cell-content {
      display: flex;
      align-items: center;
      word-break: break-word;
      padding: 0px 10px;
      margin: 15px 0px;
      height: calc(100% - 30px);
    }

    &:empty {
      display: none;
    }

    &:hover {
      cursor: pointer;

      .row-cell {
        background-color: #333333;
        color: #fff;
      }

      .status-details {
        .icon {
          color: #219653
        }
      }

      .cell-divider {
        border-color: #4f4f4f;
      }

      .icon {
        color: #fff;
      }

      .exported {
        &::before {
          color: white;
          background-image: linear-gradient(135deg, #219653 50%, #333333 50%);
        }
      }

      .deleted {
        &::before {
          color: white;
          background-image: linear-gradient(135deg, #F2665E 50%, #333333 50%);
        }
      }
    }
  }

  .tr-detail {
    &:hover {
      .cell-divider {
        border-color: #E0E0E0;
      }

      .row-cell {
        background-color: inherit;
        color: inherit;
      }
    }
  }

  .tbl-details {
    width: 100%;
    cursor: auto;
    height: 1px;

    .row-cell {
      background-color: transparent;
    }

    .cell-divider {
      border-color: #E0E0E0;
    }

    .cell-content {
      align-items: baseline;
    }
  }

  .ad-tbl-hd {
    text-align: center;
    padding: 5px;
    height: 100%;
  }

  .additional {
    align-items: center;

    span:nth-child(1) {
      font-size: 11px;
      font-weight: 600;
    }

    span:nth-child(2) {
      font-size: 10px;
    }
  }

  .assign-history {
    padding: 5px 0px;

    .row {
      &:nth-child(1) {
        width: 100%;
        align-items: baseline;
        justify-content: space-between;

        span:nth-child(1) {
          flex: 1;
        }
      }

      &:nth-child(2) {
        align-items: baseline;

        span:nth-child(1) {
          word-break: initial;
          padding: 0px 5px;
        }
      }

    }

    &:not(:last-child) {
      .line {
        margin-top: 8px;
        width: 20%;
        height: 1px;
        align-self: center;
        background: #BDBDBD;
      }
    }

    &:last-child {
      .line {
        width: 0%;
      }
    }
  }
}

.api-progress {
  position: relative !important;
  visibility: hidden;
  height: 6px !important;
  top: 2px;

  &.show {
    visibility: visible;
  }
}

.toggle-sec {
  max-height: 0;
  transition: max-height 0.5s ease-out;
  transition-delay: 0s;
  overflow: hidden;

  &.open {
    max-height: 10000px;
    transition: max-height 0.5s ease-in;
    overflow: unset;
  }
}

.rotate-icon {
  cursor: pointer;
  color: #BDBDBD;
  transition: transform 0.5s ease-out;
  transform: rotate(0deg);

  &.rotate {
    transform: rotate(180deg);
    transition-timing-function: ease-in;
  }
}

.filter-section {
  width: 80%;
  border-radius: 8px;

  &:nth-child(2) {
    width: calc(20% - 20px);
    margin-left: 20px;
    position: relative;

    .group-link {
      width: calc(100% - 32px);
      position: absolute;
      bottom: 24px;
    }
  }

  .billing {
    .note {
      margin: 10px 0;
    }
  }
}

.task-filter{
  .filter{
    .status.layout-horizontal{
      .ant-form-item-control{
        flex: 1;
      }
      .ant-form-item-label{
        display: flex;
        align-items: center;
      }
    }
  }
}

.task-table-container {
  min-height: 400px;
  .table-ct{
    padding-left: 0;
    padding-right: 0;
  }
}

.h1 {
  font-size: 36px;
  font-weight: bold;
}

.h2 {
  font-size: 21px;
  font-weight: bold;
}

.h3 {
  font-size: 16px;
  font-weight: bold;
}

.h4 {
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

.h5 {
  font-size: 16px;
  font-weight: normal;
}

.h6 {
  font-size: 14px;
  font-weight: normal;
}

.h7 {
  font-size: 12px;
  font-weight: normal;
}


.sh {
  opacity: 0.35;
}

.assign-cell {
  .close {
    margin-left: 8px;
    width: 22px;
    min-width: 22px;
    height: 22px;
  }

  .normal {
    margin-top: 8px;
  }

  .actions .btn {
    min-width: 40%;
  }
}

.addline {
  margin: 0 5px;

  span {
    height: 1px;
    width: 100%;
    border-top: 1px solid #828282;
  }
}

.task-independent {
  .pr-indicator {
    background-image: linear-gradient(138deg, #e5b41f 50%, transparent 50%) !important;
    color: #fff;
    opacity: 1 !important;
  }
}

.trans-table {
  .pad {
    padding: 0 10px;
  }

  .contact {
    width: calc(46% - 5px);
    border-right: 1px solid #E0E0E0;
  }

  .history {
    padding: 0 10px;

    .list {
      padding-inline-start: 14px;
      background-image: linear-gradient(to right, #bdbdbd 33%, rgba(255, 255, 255, 0) 0);
      background-position: bottom;
      background-size: 9px 1px;
      background-repeat: repeat-x;
    }
  }

  &.rc-table {
    table {
      border-spacing: 0 !important;
    }

    .ant-table-cell {
      padding: 5px;
      background-color: transparent;
    }

    .cell {
      border-right: none !important;
      text-align: center !important;
    }

    .ant-table-header {
      background: transparent;
      box-shadow: none !important;
    }
  }
}

.form-dialog.task-del {
  .points {
    list-style: none;
    margin-left: 0;
    padding-left: 1em;
    text-indent: -1em;

    li:before {
      content: "-";
      padding-right: 5px;
    }
  }
}

.form-dialog.task-close {
  .add-rc-sec {
    margin: 16px 0;

    .btn {
      min-width: 90px;
      margin: 0 8px;
      height: 36px;
    }

  }

  .type-checkbox {
    width: 40px;
    margin: 0;
  }

  .details {
    margin-right: 6px;
  }
  .gap{
    margin-right: 8px;
  }
}

.user-table {
  width: 90% !important;
}

.user-crud {
  width: 680px !important;
}

.user-disable-modal {
  width: 880px !important;

  .points {
    list-style: none;
    margin: 16px 0 32px 0;
    padding-left: 1em;
    text-indent: -1em;

    li:before {
      content: "-";
      padding-right: 5px;
    }
  }
}

.contacts-table {
  width: 80% !important;
}

.forget-user-modal {
  .message {
    margin-top: 24px;
  }
}

.blinking {
  animation: blink 1.25s infinite;
}

@keyframes blink {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

.project-new {
  .notes-history-sec{
    margin: 4px 0 0 8px;
    .entry{
      margin: 0 0 4px 16px;
    }
  }
  .file-list {
    margin: 2px 0 0 0;
    .file-entry:last-child{
      margin-bottom: 8px;
    }
  }
  .limit-note{
    margin-top: 10px;
  }
  .doc-set-label {
    margin-bottom: 16px;
  }
  .update-file{
    border-color: #BDBDBD !important;
    .label{
      color: #BDBDBD !important;
    }
  }
  .add-set {
    .note {
      margin-top: 10px;
    }
    .ant-btn {
      box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12);
    }
  }
}