.navbar {
  max-height: 100px;
  padding: 25px 30px;
  position: relative;
  z-index: 2;
  background-color: #333333;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .21),
    -20px 4px 10px 0 rgba(0, 0, 0, .14),
    0 1px 18px 0 rgba(0, 0, 0, .12);
  -moz-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2),
    -20px 4px 10px 0 rgba(0, 0, 0, .14),
    0 1px 18px 0 rgba(0, 0, 0, .12);

  .logo-icarus {
    height: 40px;
    margin-right: 10px;
    cursor: pointer;
  }

  .link-btn {
    width: 108px;
    height: 32px;
    border-radius: 24px;
    border: 2px solid #FFF;
    margin: 0px 8px;
    text-align: center;
    line-height: 30px;
  }
  .caret{
    margin: 12px 0 0 8px;
  }
  .new-btn{
    margin-right: 48px;
    min-width: 88px;
    white-space: nowrap;
  }
}