.projectContainer {
  height: 100%;
  background-color: #E5E5E5;
  padding: 30px;
  overflow: auto;
}

.projects {
  padding-bottom: 8px !important;
  .filter-section {
    width: calc(20% - 20px);
    margin-left: 20px;
    .ant-collapse-content-box {
      height: 100%;
    }
    .ant-collapse-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden auto;
    }

    .type-checkbox .ant-checkbox-wrapper {
      width: 100%;
    }
    .billing{
      padding: 0;
      .btn{
        margin: 6px 0;
        height: 36px;
        min-width: unset;
      }
    }
  }

  .table-ct {
    padding: 0;
  }
}

.project-row {
  .indicator {
    padding: 5px;
    border-radius: 10px;
    height: 20px;
    min-width: 20px;
    word-break: keep-all;
  }
}

.project-row-expand {
  .note-text {
    margin-left: 8px;
  }

  .dotted-line {
    background-image: linear-gradient(to right, #E0E0E0 33%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 10px 2px;
    background-repeat: repeat-x;
    margin-right: 10px;
    margin-bottom: 15px;
    height: 10px;
  }

  .doc-set-row {
    margin: 0 10px 7px 0;
    padding: 0 8px;
    .btn {
      margin-left: 16px;
    }
  }

  .item-fee {
    margin-bottom: 4px;
    padding: 0 8px;
  }

  .btn-fee {
    padding: 0;
    height: 20px;
    margin: 0 0 4px 6px;

    .ant-btn-icon {
      margin-inline-end: 2px !important;
    }

    &:hover {
      background-color: transparent !important;
    }
  }
}

.projectHeader {
  width: 100%;
  border-right: 1px solid #F2F2F2;
  padding: 12px 5px 10px 5px;
  text-align: left;
}

.projectDetails {
  //display: flex;
  width: 100%;
  border: none;
  background-color: #FFF;
  padding: 9px 2px;
  border-radius: 8px 8px 8px 8px;
  flex: 8;
  margin-bottom: 10px;
  cursor: pointer;
}

.projectDetails:hover {
  background-color: #333333;
  color: white
}

.projectDetails.row .projectHeader.h4 {
  flex: 1;
}

.projectDetails.row .projectHeader.h4:first-child,
.projectDetails.row .projectHeader.h4:last-child {
  flex: 0.5;
}

.pagination {
  //display: flex;
  width: 100%;
  border: none;
  background-color: #FFF;
  padding: 9px 2px;
  border-radius: 8px 8px 8px 8px;
  flex: 8;
  margin-bottom: 10px;
  justify-content: flex-end;
  min-height: 56px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54)
}

.projectRows {
  width: 100%;
  border-right: 1px solid #F2F2F2;
  padding: 12px 5px 10px 5px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ff4081;
  border-color: #ff4081;
}

.projectExportIcon {
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: white;
  padding: 5px;
  border-radius: 10px;
  height: 20px;
  width: 20px;
  // display: flex;
  // align-items: center;
  justify-content: center;
  word-break: keep-all;
  background: #BDBDBD;
}

.filterContainer {
  width: 20%;
  background-color: #FFF;
  padding: 20px;
  border-radius: 8px 8px 8px 8px;
  margin-left: 20px;
}

.filterHeader {
  justify-content: space-between;
  border-bottom: 1px solid #E0E0E0;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.transparentBtns {
  border: none;
  background: transparent;
}

.filterCriteria {
  padding-bottom: 30px;

  div {
    color: #828282;
  }
}

.filterSelect {
  width: 100%;
  margin-top: 1rem;
}

.filterCheckHeader {
  color: #828282;
  padding-bottom: 10px;
}

.filterCheck {
  height: 30px;
}

.project-view {
  .view-details {
    padding: 16px;

    .gap {
      margin-left: 10px;
    }

    .name-sec {
      margin-top: 20px;
    }

    .desc {
      margin: 5px 0 17px 0;
    }
  }

  .view-deliverables {
    padding: 16px;
    box-shadow: inset 4px 4px 8px rgba(0, 0, 0, .08);
    .table-ct{
      padding: 0;
    }
    .del-table-container {
      // min-height: 400px;
    }

    .exported {
      .pr-indicator {
        font-weight: bold;
        font-size: 9px;
        line-height: 12px;
        color: white;
        width: 115px;
        height: 13px;
        padding-left: 8px;
        border-top-left-radius: inherit;
        background-image: linear-gradient(135deg, #219653 50%, #FFF 50%);
        opacity: 1;
      }

      &:hover {
        .pr-indicator {
          background-image: linear-gradient(135deg, #219653 50%, #333333 50%);
        }
      }
    }

    .del-expand {
      width: 72%;
    }
  }
}
.project-invoice-add{
  .ant-form{
    margin-top: 24px;
    .ant-form-item-control-input{
      padding-left: 0;
    }
  }
}

