.form-dialog {
  min-width: 680px;

  .container {
    margin-top: 24px;
  }

  .title-sec {
    padding-bottom: 20px;
    border-bottom: 1px solid #000000;
  }

  .dialog-actions {
    .btn {
      min-width: 130px;
      margin-left: 8px;
    }
  }
}
.transparent-dialog{
  .ant-modal-content{
    background-color: transparent;
    box-shadow: none;
  }
}