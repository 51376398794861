.file-view-modal {
  width: 100vw !important;
  height: 100vh !important;
  top: 0 !important;
  max-width: unset !important;
  padding-bottom: 0;
  .ant-modal-content{
    height: 100%;
    width: 100%;
    background: transparent;
    padding: 0;
  }
  .ant-modal-body{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.file-dialog {
  .mat-dialog-container {
    padding: 0 !important;
    background-color: transparent !important;
  }
}

.header {
  .fa {
    color: #FFF;
    font-size: 22px;
  }

  .p-name {
    font-size: 20px;
  }
  .file-name {
    margin-left: 15px;
    line-height: 40px;
    color: white;
    word-break: break-word;
  }

  .row {
    word-break: break-word;
    margin-left: 20px;
    align-items: baseline;

    button {
      min-width: unset;
      padding: 5px;
    }
  }
  .download{
    padding: 16px 40px 16px 16px;
  }
}

.fview {
  width: 100%;
  height: 100%;
  overflow: auto;

  iframe {
    width: 100%;
    height: 100%;
  }

  img {
    max-height: 100%;
    overflow: auto;
  }
}

.file-view {
  margin-top: 20px;
  max-width: 60vw;
  width: 100%;
  max-height: 100%;
  background-color: white;
  flex: 1;
  margin-bottom: 30px;
  overflow: auto;
}