@import "./font.scss";
@import "./icon.scss";
@import "./color.scss";

* {
  box-sizing: border-box !important;
  -moz-appearance: none !important;
  appearance: none !important;
  -webkit-appearance: none !important;
}

.d-flex {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
}

.col {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
}

.row {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.row-reverse {
  flex-direction: row-reverse;
}

.v-ctr {
  align-items: center;
}

.h-ctr {
  justify-content: center;
}

.v-start {
  align-items: flex-start;
}

.v-end {
  align-items: flex-end;
}

.h-btn {
  justify-content: space-between;
}

.h-arnd {
  justify-content: space-around;
}

.h-end {
  justify-content: flex-end;
}

.self-ctr{
  align-self: center;
}
.flex-full,
.f-rest {
  flex: 1;
}

.fwarp {
  flex-wrap: wrap;
}

.f-rest-2 {
  flex: 2;
}

.text-center {
  text-align: center;
}

.o-hide {
  overflow: hidden;
}

.no-wrap {
  white-space: nowrap;
}

.hide {
  display: none !important;
}

.oy-auto {
  overflow: hidden auto;
}

body {
  width: 100vw;
  height: 100vh;
  margin: 0;
}

.ant-app {
  font-size: inherit;
  font-family: 'Roboto';
  color: inherit;
}

.w-100 {
  width: 100%;
}

.w-49 {
  width: 49%;
}

.w-50 {
  width: 50%;
}

.w-32 {
  width: 32%;
}

.w-24 {
  width: 24%;
}

.w-30 {
  width: 30%;
}

.w-auto {
  width: auto;
}

.w-content {
  width: fit-content;
}

.h-100 {
  height: 100%;
}

.txt-new-line {
  word-break: break-all;
}

.text-up {
  text-transform: uppercase;
}

.caps {
  text-transform: capitalize;
}

.pointer {
  cursor: pointer;
}

.nav-menu {
  min-width: 180px !important;

  .menu-item {
    min-width: 180px;
  }

  .menu-new {
    min-width: auto;
    height: 24px;
    width: 60px;
    padding: 0;
    z-index: 2;
  }
}

.screen-pad {
  padding: 16px 16px 0 16px;
}

.ant-form-item-required:before {
  content: "*";
  position: absolute;
  right: 0;
  color: red;
  /* You can change the color of the required marker */
}

.brk-word {
  word-break: break-word;
}

.cc,
.filter-section .ant-collapse-content,
.doc-table .ant-table-body {
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #8a9bb2;
    border-radius: 8px;
    border: 2px solid #f2f2f2;
  }
}