.btn {
  border-radius: 24px;
  -webkit-appearance: none;
  border: none;
  padding: 10px 20px;
  min-width: 160px;
  cursor: pointer;
  height: auto;
  width: fit-content;

  &.link {
    padding: 0;
    min-width: unset;
    background-color: transparent;
    &:hover {
      .label {
        text-decoration: underline;
      }
    }
  }
  &:hover{
    opacity: 0.8;
  }
  &.normal{
    border-radius: 2px;
    min-height: 25px;
    padding: 0px 6px;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12);
  }
  &.no-shadow{
    box-shadow: none;
  }
  &:disabled{
    cursor: not-allowed;
    box-shadow: none !important;
  }
}